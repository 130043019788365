import React, { useState } from "react";
import "./graphics&illustrations.css";

// Dynamically import all images from the folder
const importAll = (requireContext) =>
  requireContext.keys().map(requireContext);

const images = importAll(
  require.context("../../../../assets/designer-pages/design-work/graphics&illustrations", false, /\.(png|jpe?g|svg)$/)
);

const GraphicsIllustrations = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const openPopup = (index) => {
    setCurrentImageIndex(index);
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const showNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const showPreviousImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="graphics-page">
      <h1 className="title">Graphics & Illustrations</h1>
      <div className="graphics-grid">
        {images.map((imgSrc, index) => (
          <div key={index} className="grid-item" onClick={() => openPopup(index)}>
            <img src={imgSrc} alt={`Graphic ${index + 1}`} loading="lazy" />
          </div>
        ))}
      </div>

      {/* Popup */}
      {isPopupOpen && (
        <div className="popup-overlay" onClick={closePopup}>
          <div className="popup-content" onClick={(e) => e.stopPropagation()}>
            <button className="close-button" onClick={closePopup}>
              &times;
            </button>
            <button className="nav-button left" onClick={showPreviousImage}>
             &#8249;
            
             
            </button>
            <img src={images[currentImageIndex]} alt={`Graphic ${currentImageIndex + 1}`} />
            <button className="nav-button right" onClick={showNextImage}>
               &#8250;
           
           
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default GraphicsIllustrations;
