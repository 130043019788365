import React from 'react';
import '../design-pages.css';
import { Link } from 'react-router-dom'; // Use React Router for navigation
import graphicsIllustrations from '../../../assets/designer-pages/design-work/graphics&illustrations/graphics&illustrations.png';
import logos from '../../../assets/designer-pages/design-work/logos/logos.png';
import uxui from '../../../assets/designer-pages/design-work/ux&ui/ux&ui.png';

const DesignWork = () => {
  const designWork = [
    {
      id: 1,
      title: 'Graphics & Illustrations',
      description:
        'All sorts of graphics. From sports posters to infographics to shirt designs and illustrations, this is the main collection of all my works over the past few years. Primarily made in Photoshop and posted on my social media page, @wolvesplug.',
      image: graphicsIllustrations,
      link: '/design-work/graphics&illustrations',
   
    },
    {
      id: 2,
      title: 'Logos',
      description:
        'This is a collection of all logos. Primarily made in Adobe Illustrator.',
      image: logos,
      link: '/design-work/logos',
    
    },
    //{
     // id: 3,
     // title: 'UX & UI',
     // description:
     //   'This involves prototypes and mockups for websites. Made in Figma.',
     // image: uxui,
     // link: '/design-work/ux&ui',
   
    //},
  ];

  return (
    <div className="design-pages">
      <h1 className="title">Design Work</h1>
      <div className="design-pages-list">
        {designWork.map((study) => (
          <div key={study.id} className="design-page">
            <div className="design-page-details">
              <h2>{study.title}</h2>
              <p>{study.description}</p>
              <Link to={study.link} className="view-button">
                View
              </Link>
            </div>
            <img
              src={study.image}
              alt={`${study.title} logo`}
              className="design-page-image"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default DesignWork;