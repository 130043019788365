import React from "react";
import "./animations.css";

const animations = [
  {
    title: "Waves",
    thumbnail: "https://via.placeholder.com/1080x1350", // Replace with actual thumbnail URL
    videoUrl:
      "https://1drv.ms/v/c/87709054a220d252/IQSmRQCkcXcpTYKnYSrcnkpNASkjHEdVUdgeD8ozdEjPo0U", // Replace with actual video URL
  },
  //{
  //  title: "Waves",
  //  thumbnail: "https://via.placeholder.com/1080x1350", // Replace with actual thumbnail URL
  //  videoUrl:
  //    "https://1drv.ms/v/c/87709054a220d252/IQSmRQCkcXcpTYKnYSrcnkpNASkjHEdVUdgeD8ozdEjPo0U", // Replace with actual video URL
  // },
];

const Animations = () => {
  return (
    <div className="animations-page">
      <h1 className="title">Animations</h1>
      <div className="animations-grid">
        {animations.map((video, index) => (
          <div key={index} className="grid-item">
            {/* Embed Video */}
            <div className="responsive-video">
              <iframe
                src={video.videoUrl}
                frameBorder="no"
                scrolling="no"
                allowFullScreen
                title={`Video-${index + 1}`} // Unique title for each iframe
              ></iframe>
            </div>
       
          </div>
        ))}
      </div>
    </div>
  );
};

export default Animations;
