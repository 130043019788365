import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/navbar/navbar';
import Footer from './components/footer/footer';


import Home from './pages/_general/home/home';

import About from './pages/_general/about/about';

import CaseStudies from './pages/design-pages/case-studies/case-studies';
import Study1 from './pages/design-pages/case-studies/study1';
import Study2 from './pages/design-pages/case-studies/study2';
import Study3 from './pages/design-pages/case-studies/study3';

import Projects from './pages/developer-pages/projects/projects';

import Resume from './pages/developer-pages/resume/resume';

import DesignWork from './pages/design-pages/design-work/design-work';
import GraphicsIllustrations from './pages/design-pages/design-work/graphics&illustrations/graphics&illustrations';
import Logos from './pages/design-pages/design-work/logos/logos';

import MotionGraphics from './pages/design-pages/motion-graphics/motion-graphics';
import Videos from './pages/design-pages/motion-graphics/videos/videos';
import Animations from './pages/design-pages/motion-graphics/animations/animations'



import Contact from './pages/_general/contact/contact';
import ThankYou from './pages/_general/contact/thankyou'



const App = () => {
  const [isDeveloper, setIsDeveloper] = useState(true); // Default to developer mode

  // Function to toggle between modes
  const toggleMode = () => {
    setIsDeveloper((prev) => !prev);
  };

  return (
    <Router>
      <div>
        <main>
        {/* Pass isDeveloper and toggleMode to Navbar */}
        <Navbar isDeveloper={isDeveloper} toggleMode={toggleMode} />
        <Routes>
          {/* Pass isDeveloper to Home */}
          <Route path="/" element={<Home isDeveloper={isDeveloper} />} />
          <Route path="/home" element={<Home isDeveloper={isDeveloper} />} />

          <Route path="/about" element={<About />} />
              
          <Route path="/projects" element={<Projects />} />

          <Route path="/resume" element={<Resume />} />

          <Route path="/case-studies" element={<CaseStudies />} />
          <Route path="/case-studies/study1" element={<Study1 />} />
          <Route path="/case-studies/study2" element={<Study2 />} />
          <Route path="/case-studies/study3" element={<Study3 />} />
         
          <Route path="/design-work" element={<DesignWork />} />
          <Route path="/design-work/graphics&illustrations" element={<GraphicsIllustrations />} />
          <Route path="/design-work/logos" element={<Logos />} />

          <Route path="/motion-graphics" element={<MotionGraphics />} />
          <Route path="/motion-graphics/videos" element={<Videos />} />
          <Route path="/motion-graphics/animations" element={<Animations />} />

           
          <Route path="/contact" element={<Contact />} />
          <Route path="/contact/thankyou" element={<ThankYou />} />
        
       
        </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
