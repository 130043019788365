  import React from 'react';

  import './about.css';

  import Code from '../../../assets/_general/about/code.svg';
  import WebDev from '../../../assets/_general/about/webdevelopment.svg';
  import AI from '../../../assets/_general/about/ai.svg';
  import Chip from '../../../assets/_general/about/chip.svg';

  import Add from '../../../assets/_general/about/add.svg';
  import Photo from '../../../assets/_general/about/photo.svg';
  import Video from '../../../assets/_general/about/video.svg';



  const About = () => {
    return (
      <div className="about-page">
        <main className="about-content">
          <section className="about-intro">
            <div className="intro-text">
              <h1 className="gradient-text">About</h1>
              <p>
                As a Computer Engineering student at the University of Waterloo and a Multimedia Designer for 4x NBA All-Star Karl-Anthony Towns, I combine strong analytical problem-solving with a passion for creating engaging visual experiences.
              </p>
              <p>
                I’m extremely inspired by Leonardo da Vinci. His ability to excel in every field and change the world is the versatility that I strive for every day.
              </p>
              <p>
                My work spans from developing AI-driven applications and embedded systems to designing professional-level multimedia content. With experience in both tech innovation and creative design, my goal is to continue to bridge the gap between engineering and visual design, providing solutions for complex problems.
              </p>
            </div>
            <div className="placeholder-image"></div> {/* Black square placeholder */}
          </section>

        <section className="stack-section">
          <div className="tech-stack">
            <h2 className="gradient-text">Tech Stack</h2>
            <br></br>
            <div className="stack-icons">
              <span>
                <img src={Code} alt="Languages Icon" className="icon" />
                <strong>Languages</strong> 
              </span>
              <span className="lower-text"> <br></br> C++, C#, Python3, Java, GoLang </span>
              <span>
                <img src={WebDev} alt="Web Development Icon" className="icon" />
                <strong>Web & App Development</strong> 
                
              </span>
              <span className="lower-text">   <br></br>HTML, CSS, JavaScript, React.js, Node.js, Blazor, .NET, Flutter, Mendix </span>
              <span>
                <img src={AI} alt="Data & AI Icon" className="icon" />
                <strong>Data & AI</strong>  
              </span>
              <span className="lower-text">  <br></br>SQL, TensorFlow, Keras, Kubernetes, Jupyter Notebook, MongoDB, AWS</span>
              <span>
                <img src={Chip} alt="Hardware Icon" className="icon" />
                <strong>Hardware & Embedded Systems</strong> 
              </span>
              <span className="lower-text">  <br></br> STM32 IDE, Arduino, Raspberry Pi</span>
            </div>
          </div>

          <div className="creative-stack">
            <h2 className="gradient-text">Creative Stack</h2>
            <br></br>
            <div className="stack-icons">
              <span>
                <img src={Photo} alt="Photo Icon" className="icon" />
                <strong>Photo Editing & Illustration</strong> 
              </span>
              <span className="lower-text">  <br></br> Photoshop, Lightroom, Illustrator</span>

              <span>
                <img src={Video} alt="Video Icon" className="icon" />
                <strong>Motion Graphics</strong> 
              </span>
              <span className="lower-text">  <br></br> Premiere Pro, After Effects</span>
              
              <span>
                <img src={Add} alt="Other Tools Icon" className="icon" />
                <strong>Other</strong>  
              </span>
              <span className="lower-text"> <br></br>Figma </span>
            </div>
          </div>
        </section>
        </main>

      
      </div>
    );
  };

  export default About;