import React, { useState } from 'react';
import './projects.css'; // Assuming the new CSS file for Projects
import headVolleyball from '../../../assets/developer-pages/projects/headvolleyball.png';
import firebaseChat from '../../../assets/developer-pages/projects/reactchatroom.png';
import gamedayGenerator from '../../../assets/developer-pages/projects/gamedaygenerator.png';
import nnfs from '../../../assets/developer-pages/projects/nnfs.io.png';
import comingSoon from '../../../assets/developer-pages/projects/comingsoon.png';

const Projects = () => {
  const [selectedTab, setSelectedTab] = useState('software'); // Default tab

  const projects = [
    {
      id: 1,
      title: 'NBA Gameday Generator',
      description: "Automated NBA poster generator. Using NBA data from the web, Adobe's Photoshop API, and Dropbox's API this program can generate high-level NBA Poster templates within seconds.  Using Selenium, ChromeWebDriver, BeautifulSoup, the program fetches real time NBA data to provide accurate information of posters in seconds.",
      image: gamedayGenerator, // Replace with actual path
      category: 'software',
      url: 'https://github.com/ajamous1/NBA-Gameday-Generator',
   
    },
    {
      id: 2,
      title: 'React Firebase Chat',
      description: 'A real-time messaging platform for a peer-to-peer tutoring program using React and Firebase. The system securely stores chatlogs in Firebase, accessible only by administrators, and features a matching algorithm that pairs students with tutors based on personality similarities using cosine similarity.',
      image: firebaseChat, // Replace with actual path
      category: 'software',
      url: 'https://github.com/ajamous1/React-Firebase-Chat/',
      
    },
    {
      id: 3,
      title: 'Head Volleyball',
      description: 'Using classes, inheritance, and polymorphism, this game inspired by Mousebreaker’s Head Sports flash games  takes a new spin on the classic with new characters and new power ups! Characters used are from @corndoggylol on social media, with his permission. Made in Java.',
      image: headVolleyball, // Replace with actual path
      category: 'software',
      url: 'https://github.com/ajamous1/Head-Volleyball-Game',
    },

    {
      id: 4,
      title: 'nnfs',
      description: 'Creating a Neural Network from scratch with the help of the nnfs.io book. The goal of this project is to better understand the framework of Neural Networks.',
      image: nnfs, // Replace with actual path
      category: 'ai',
      url: 'https://github.com/ajamous1/nnfs',
    },
    {
      id: 5,
      title: 'Coming Soon',
      description: 'Stay tuned for updates!',
      image: comingSoon,
      category: 'hardware',
      url: 'https://github.com/ajamous1/nnfs',
    },
  ];

  return (
    
    <div className="projects-page">
      {/* Title Section */}
      <h1 className="title">Projects</h1>

      {/* Tabs */}
      <div className="tabs">
        <button
          className={`tab ${selectedTab === 'software' ? 'active' : ''}`}
          onClick={() => setSelectedTab('software')}
        >
          Software
        </button>
        <button
          className={`tab ${selectedTab === 'hardware' ? 'active' : ''}`}
          onClick={() => setSelectedTab('hardware')}
        >
          Hardware
        </button>
        <button
          className={`tab ${selectedTab === 'ai' ? 'active' : ''}`}
          onClick={() => setSelectedTab('ai')}
        >
          AI
        </button>
      </div>

      {/* Projects Section */}
      {/* Projects Section */}
      {selectedTab === 'software' && (
        <section className="projects-list">
          {projects
            .filter((project) => project.category === 'software')
            .map((project) => (
              <div key={project.id} className="project">
                <div className="project-details">
                  <h2>{project.title}</h2>
                  <p>{project.description}</p>
                  <a
                    href={project.url}
                    className="view-button"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View
                  </a>

                  
                </div>
                <img src={project.image} alt={`${project.title} project`} className="project-image" />
              </div>
            ))}
        </section>
      )}

      {selectedTab === 'ai' && (
        <section className="projects-list">
          {projects
            .filter((project) => project.category === 'ai')
            .map((project) => (
              <div key={project.id} className="project">
                <div className="project-details">
                  <h2>{project.title}</h2>
                  <p>{project.description}</p>
                  <a
                    href={project.url}
                    className="view-button"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View
                  </a>
                </div>
                <img src={project.image} alt={`${project.title} project`} className="project-image" />
              </div>
            ))}
        </section>
      )}


{selectedTab === 'hardware' && (
        <section className="projects-list">
          {projects
            .filter((project) => project.category === 'hardware')
            .map((project) => (
              <div key={project.id} className="project">
                <div className="project-details">
                  <h2>{project.title}</h2>
                  <p>{project.description}</p>
                  <a
                    href={project.url}
                 
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                   
                  </a>
                </div>
                <img src={project.image} alt={`${project.title} project`} className="project-image" />
              </div>
            ))}
        </section>
      )}
      
    </div>
   
  );
  
};

export default Projects;