import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import './thankyou.css';


  
const ThankYou = () => {

  const navigate = useNavigate(); // Initialize useNavigate

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    navigate('/'); // Redirect to the thank-you page
  };

  return (
    <div className="thankyou-page">
    <main className="thankyou-content">
        <h1>Thank You</h1>

        <p>Your inquiry has been received. I will get back to you shortly.</p>

        <br></br>
        <form className="thankyou-form" onSubmit={handleSubmit}>

        <button type="submit">Return home</button>


        </form>
        

      

    </main>

    <div className="thankyou-image"></div> {/* Placeholder for the phone booth image */}
    </div>
  );
};

export default ThankYou;
