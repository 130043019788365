import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import './navbar_styling.css';
import developerLogo from '../../assets/_general/logos/ahmadjamouslogo-developer.svg';
import designerLogo from '../../assets/_general/logos/ahmadjamouslogo-designer.svg';

const Navbar = ({ isDeveloper, toggleMode }) => {
  const location = useLocation();
  const [menuOpen, setMenuOpen] = React.useState(false);

  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };

  const isActive = (path) => location.pathname === path;

  const toggleSwitchStyle = {
    justifyContent: isDeveloper ? 'flex-start' : 'flex-end',
    padding: '2px',
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <div className="navbar-logo">
          <Link to="/">
            <img src={isDeveloper ? developerLogo : designerLogo} alt="logo" />
          </Link>
        </div>

        <div className="menu-button" onClick={handleMenuToggle}>
          <span>{menuOpen ? '✕' : '☰'}</span>
        </div>

        <div className={`navbar-right ${menuOpen ? 'open' : ''}`}>
          <ul
            className={`navbar-links ${
              isDeveloper ? 'developer' : 'designer'
            } ${menuOpen ? 'show' : 'hideOnMobile'}`}
          >
            
            <li className={`mobile-only ${isActive('/') ? 'active' : ''}`}>
                <Link to="/">Home</Link>
            </li>

            <li className={isActive('/about') ? 'active' : ''}>
              <Link to="/about">About</Link>
            </li>
            {isDeveloper ? (
              <>
                <li className={isActive('/projects') ? 'active' : ''}>
                  <Link to="/projects">Projects</Link>
                </li>
                <li className={isActive('/resume') ? 'active' : ''}>
                  <Link to="/resume">Resume</Link>
                </li>
              </>
            ) : (
              <>
                <li className={isActive('/case-studies') ? 'active' : ''}>
                  <Link to="/case-studies">Case Studies</Link>
                </li>
                <li className={isActive('/design-work') ? 'active' : ''}>
                  <Link to="/design-work">Design Work</Link>
                </li>
                <li className={isActive('/motion-graphics') ? 'active' : ''}>
                  <Link to="/motion-graphics">Motion Graphics</Link>
                </li>
              </>
            )}
            <li
              className={`desktop-only ${
                isActive('/contact') ? 'active' : ''
              }`}
            >
              <Link to="/contact">Contact</Link>
            </li>
          </ul>

          <a
            href="/contact"
            className={`contact-button ${
              isDeveloper ? 'blue' : 'orange'
            } hideOnMobile`}
          >
            Contact
          </a>

          <div
            className={`toggle-switch ${isDeveloper ? 'blue' : 'orange'}`}
            onClick={toggleMode}
            style={toggleSwitchStyle}
          >
            <div className="toggle-knob"></div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
