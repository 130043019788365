import React from 'react';
import './study.css'; // Updated CSS
import MSL from '../../../assets/designer-pages/case-studies/study3/msl.png';
import MSLPrimary from '../../../assets/designer-pages/case-studies/study3/mslprimary.png';
import MSLExtended from '../../../assets/designer-pages/case-studies/study3/mslextended.png';
import MSLFixtures from '../../../assets/designer-pages/case-studies/study3/mslmockup1.png';
import MSLFT from '../../../assets/designer-pages/case-studies/study3/mslmockup2.png';

const Study1 = () => {
  return (
    <div className="study-page">
      <h1 className="title">Milton Super League</h1>
      <section className="content-section">
        {/* The Icon */}
        <div class="icon-section">
      <div class="icon-container">
        <h2>The Icon</h2> 
        <p>
        This logo for the Milton Super League incorporates a bold, dynamic M, which shields a football at the center, representing both strength and the sport itself. The star at the top symbolizes excellence, while the clean, geometric design reflects the competitive spirit and unity within the local community football league.
        </p>
      </div>
      <img src={MSL} alt="MSL Icon" />
    </div>


        {/* The Type */}
        <div className="type-section">
        <div className="font-square-3">Aa</div>
        <div className="text-content">
          <h2>The Type</h2>
          <p>
          Bebas Neue’s bold, geometric style perfectly complements the Milton Super League's strong and competitive image. Its clean lines and modern look ensure readability and accessibility, reinforcing the league’s energetic and professional branding across all platforms.
          </p>
        </div>
      </div>

        {/* Palette */}
        <div className="palette-section">
          <h2>Palette</h2>
          <div className="palette-colors">
            {[{ color: '#5E2E87', label: '#5E2E87' },
              { color: '#1C0333', label: '#1C0333' },
              { color: '#FFFFFF', label: '#FFFFFF' }
            ].map(c => (
              <div className="color-container" key={c.label}>
                <div
                  className="color"
                  style={{
                    backgroundColor: c.color,
                    border: c.color === '#FFFFFF' ? '1px solid black' : 'none',
                  }}
                ></div>
                <p>{c.label}</p>
              </div>
            ))}
          </div>
        </div>

        {/* Logomarks */}
        <div className="logomarks-section">
          <h2>Logomarks</h2>
          <div className="logomarks">
          
            <div className="logomark-item stacked">
              <div className="logomark-subitem">
                <img src={MSLPrimary} alt="MSL Primary" />
                <span>Primary Logomark</span>
              </div>
              <div className="logomark-subitem">
                <img src={MSLExtended} alt="MSL Extended" />
                <span>Extended Logomark</span>
              </div>
            </div>
        
            <div className="logomark-item condensed">
              <img src={MSL} alt="MSL Condensed" />
              <span>Condensed Logomark</span>
            </div>
          </div>
        </div>

        {/* Mockups */}
        <div className="mockups-section">
          <h2>Mockups</h2>
          <div className="mockups">
            <div>
              <img src={MSLFixtures} alt="NextWave Gameday" />
             
            </div>
            <div>
              <img src={MSLFT} alt="NextWave Postgame" />
              
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Study1;
