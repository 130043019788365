import React from "react";
import "./videos.css";

const videos = [
  {
    title: "2024-25 NBA Opening Night",
    thumbnail: "https://via.placeholder.com/1920x1080", // Replace with actual thumbnail URL
    videoUrl: "https://1drv.ms/v/c/87709054a220d252/IQQc2d50_ROHRLJ8E6KeCWIEAREtAFq7jmfIOxmTolGvlPY", // Replace with actual video URL
  },
  {
    title: "2022 WC Final",
    thumbnail: "https://via.placeholder.com/1920x1080", // Replace with actual thumbnail URL
    videoUrl: "https://1drv.ms/v/c/87709054a220d252/IQRS0iCiVJBwIICHsxwAAAAAAbUpJluA2ZuMApaKDJYMSwk", // Replace with actual video URL
  },
];

const Videos = () => {
  return (
    <div className="videos-page">
    <h1 className="title">Videos</h1>
    <div className="videos-grid">
      {videos.map((video, index) => (
        <div key={index} className="grid-item">
          {/* Embed Video */}
          <div className="responsive-video">
            <iframe
              src={video.videoUrl}
              frameBorder="no"
              scrolling="no"
              allowFullScreen
              title={`Video-${index + 1}`} // Unique title for each iframe
            ></iframe>
          </div>
     
        </div>
      ))}
    </div>
  </div>
  );
};

export default Videos;
