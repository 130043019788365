import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import emailjs from 'emailjs-com';
import './contact.css';

const Contact = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { id, value } = e.target;

    // If the field being updated is the phone field, allow only numbers, dashes, and spaces
    const updatedValue = id === 'phone' ? value.replace(/[^0-9\-\s]/g, '') : value;

    setFormData({
      ...formData,
      [id]: updatedValue,
    });
  };

  const validate = () => {
    const newErrors = {};

    if (!formData.name.trim()) newErrors.name = true;
    if (!formData.email.trim()) newErrors.email = true;
    if (!formData.phone.trim()) {
      newErrors.phone = true;
    } else if (!/^[0-9\-\s]+$/.test(formData.phone)) {
      newErrors.phone = true;
    } else {
      const digitCount = formData.phone.replace(/[^0-9]/g, '').length;
      if (digitCount !== 10) {
        newErrors.phone = true;
      }
    }
    if (!formData.message.trim()) newErrors.message = true;

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      emailjs
        .send(
          process.env.REACT_APP_EMAILJS_SERVICE_ID,
          process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
          formData,
          zxgdbEGnEK8xruDVP
        )
        .then((response) => {
          console.log('SUCCESS!', response.status, response.text);
          navigate('/contact/thankyou');
        })
        .catch((error) => {
          console.error('FAILED...', error);
        });
    }
  };

  return (
    <div className="contact-page">
      <main className="contact-content">
        <h1>Contact</h1>
        <form className="contact-form" onSubmit={handleSubmit}>
          <label htmlFor="name">
            Name<span className={errors.name ? 'required' : ''}>*</span>
          </label>
          <input
            type="text"
            id="name"
            placeholder="First Last"
            value={formData.name}
            onChange={handleChange}
            className={errors.name ? 'error' : ''}
          />

          <label htmlFor="company">Company (optional)</label>
          <input
            type="text"
            id="company"
            placeholder="Company Name"
            value={formData.company || ''}
            onChange={handleChange}
          />

          <label htmlFor="email">
            Email<span className={errors.email ? 'required' : ''}>*</span>
          </label>
          <input
            type="email"
            id="email"
            placeholder="name@email.com"
            value={formData.email}
            onChange={handleChange}
            className={errors.email ? 'error' : ''}
          />

          <label htmlFor="phone">
            Phone<span className={errors.phone ? 'required' : ''}>*</span>
          </label>
          <input
            type="tel"
            id="phone"
            placeholder="123-456-7890"
            value={formData.phone}
            onChange={handleChange}
            className={errors.phone ? 'error' : ''}
          />

          <label htmlFor="message">
            Message<span className={errors.message ? 'required' : ''}>*</span>
          </label>
          <textarea
            id="message"
            placeholder="Enter your message here..."
            value={formData.message}
            onChange={handleChange}
            className={errors.message ? 'error' : ''}
          ></textarea>

          <button type="submit">Get in touch!</button>
        </form>
      </main>
      <div className="contact-image"></div>
    </div>
  );
};

export default Contact;
