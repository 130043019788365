import React from 'react';
import '../design-pages.css';
import { Link } from 'react-router-dom'; // Use React Router for navigation
import videos from '../../../assets/designer-pages/motion-graphics/videos/videos.png';
import animations from '../../../assets/designer-pages/motion-graphics/animations/animations.png';


const MotionGraphics = () => {
  const motionGraphics = [
    {
      id: 1,
      title: 'Videos',
      description:
        'Video edits made in Adobe Premiere Pro.',
      image: videos,
      link: '/motion-graphics/videos',
   
    },
    {
      id: 2,
      title: 'Animations',
      description:
        'Animations made using Adobe After Effects.',
      image: animations,
      link: '/motion-graphics/animations',
    
    },
  ];

  return (
    <div className="design-pages">
      <h1 className="title">Motion Graphics</h1>
      <div className="design-pages-list">
        {motionGraphics.map((study) => (
          <div key={study.id} className="design-page">
            <div className="design-page-details">
              <h2>{study.title}</h2>
              <p>{study.description}</p>
              <Link to={study.link} className="view-button">
                View
              </Link>
            </div>
            <img
              src={study.image}
              alt={`${study.title} logo`}
              className="design-page-image"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default MotionGraphics;