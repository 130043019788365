import React from "react";
import './footer_styling.css';
import behance from '../../assets/_general/social/behancelogo.svg';
import github from '../../assets/_general/social/githublogo.svg'
import twitter from '../../assets/_general/social/xlogo.svg'
import linkedin from '../../assets/_general/social/linkedinlogo.svg'
import insta from '../../assets/_general/social/iglogo.svg'
import logo from '../../assets/_general/logos/ahmadjamouslogo.svg'
import { Link } from 'react-router-dom';

const Footer=()=>{
    return (
        <div className="footer">


                <div className="sb_footer-below">

                <p>
                <Link to="/home">
                    <img width="35px" src={logo} alt="Home" />
                </Link>
                </p>

                    <div className="sb_footer-copyright">
                            <p>
                                ©{new Date().getFullYear()} Ahmad Jamous

                            </p>



                            
                        

                    </div>

                    <div className="socialmedia">
                        <a href="https://www.instagram.com/ahmadjamous_" target="_blank" rel="noopener noreferrer">
                        <img width="24px" src={insta} alt="Instagram" />
                        </a>
                        <a href="https://twitter.com/ahmadjamous_" target="_blank" rel="noopener noreferrer">
                        <img width="24px" src={twitter} alt="Twitter" />
                        </a>
                        <a href="https://www.linkedin.com/in/ahmadjamous-eng/" target="_blank" rel="noopener noreferrer">
                        <img width="24px" src={linkedin} alt="LinkedIn" />
                        </a>
                        <a href="https://github.com/ajamous1" target="_blank" rel="noopener noreferrer">
                        <img width="24px" src={github} alt="GitHub" />
                        </a>
                        <a href="https://www.behance.net/ahmadjamous" target="_blank" rel="noopener noreferrer">
                        <img width="24px" src={behance} alt="Behance" />
                        </a>
                    </div>
           


                   
                  
                </div>

            <hr/>

            <div className = "sb_footer section_padding">
                <div className="sb_footer-links">
                    <div className="sb_footer-links_div">
                        <a href="/">
                            <p>Home</p>
                        
                        </a>
                        <a href="/about">
                            <p>About</p>
                        
                        </a>
                        <a href="/contact">
                            <p>Contact</p>
                        
                        </a>

                    </div>
                    <div className="sb_footer-links_div">
                        <a href="/projects">
                            <p>Projects</p>
                        
                        </a>
                        <a href="/resume">
                            <p>Resume</p>
                        
                        </a>
    
                    </div>

                    <div className="sb_footer-links_div">
                        <a href="/case-studies">
                            <p>Case Studies</p>
                        
                        </a>

                        <a href="/design-work">
                            <p>Design Work</p>
                        
                        </a>

                        <a href="/motion-graphics">
                            <p>Motion Graphics</p>
                        
                        </a>
                    </div>


                   

                </div>

              


            </div>


        </div>
    )
}

export default Footer;