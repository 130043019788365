import React from 'react';
import '../design-pages.css';
import { Link } from 'react-router-dom'; // Use React Router for navigation
import hawksTalk from '../../../assets/designer-pages/case-studies/study1/hawkstalk.png';
import nextWave from '../../../assets/designer-pages/case-studies/study2/nextwave.png';
import superLeague from '../../../assets/designer-pages/case-studies/study3/msl.png';

const CaseStudies = () => {
  const caseStudies = [
    {
      id: 1,
      title: 'Hawks Talk',
      description:
        'A social media page covering the Atlanta Hawks looking to establish a good and interactive brand where people can learn about the team and make it easier for fans to access knowledge about the Hawks.',
      image: hawksTalk,
      link: '/case-studies/study1',
   
    },
    {
      id: 2,
      title: 'NextWave',
      description:
        'A consulting agency looking to help students with professional growth. With the goal of providing students with an opportunity to interact with student professionals who have industry, academic, and various other experiences that they can share with students who are looking to accomplish the same things.',
      image: nextWave,
      link: '/case-studies/study2',
    
    },
    {
      id: 3,
      title: 'Milton Super League',
      description:
        'This was a brand identity done for a local football league. The Milton Super League is a league made by the players for the players, and is an opportunity for the community to get together and play high-level competitive football games together.',
      image: superLeague,
      link: '/case-studies/study3',
   
    },
  ];

  return (
    <div className="design-pages">
      <h1 className="title">Case Studies</h1>
      <div className="design-pages-list">
        {caseStudies.map((study) => (
          <div key={study.id} className="design-page">
            <div className="design-page-details">
              <h2>{study.title}</h2>
              <p>{study.description}</p>
              <Link to={study.link} className="view-button">
                View
              </Link>
            </div>
            <img
              src={study.image}
              alt={`${study.title} logo`}
              className="design-page-image"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default CaseStudies;