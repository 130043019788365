import React from 'react';
import './study.css'; // Updated CSS
import nextWave from '../../../assets/designer-pages/case-studies/study2/nextwave.png';
import nextWavePrimary from '../../../assets/designer-pages/case-studies/study2/nextwaveprimary.png';
import nextWaveExtended from '../../../assets/designer-pages/case-studies/study2/nextwaveextended.png';
import nextWaveBusinessCard from '../../../assets/designer-pages/case-studies/study2/nextwavemockup1.png';
import nextWaveBG from '../../../assets/designer-pages/case-studies/study2/nextwavemockup2.png';

const Study1 = () => {
  return (
    <div className="study-page">
      <h1 className="title">NextWave</h1>
      <section className="content-section">
        {/* The Icon */}
        <div class="icon-section">
      <div class="icon-container">
        <h2>The Icon</h2> 
        <p>
        A simple wave formed by the letters of the brand, “N” and “W”. This free flowing icon is simple yet effective in encapsulating the brand’s image: collaboration, community, and growth. The wave also serves as demonstration of the ups and downs of navigating through your professional career, and how NextWave is there to guide you through that process.
        </p>
      </div>
      <img src={nextWave} alt="NextWave Icon" />
    </div>


        {/* The Type */}
        <div className="type-section">
        <div className="font-square-2">Aa</div>
        <div className="text-content">
          <h2>The Type</h2>
          <p>
          Inter’s clean, minimalistic design conveys professionalism and trust, aligning with NextWave Consulting’s mission to guide young professionals. Its modern simplicity ensures easy readability, keeping the focus on the services while maintaining a sophisticated, approachable tone.
          </p>
        </div>
      </div>

        {/* Palette */}
        <div className="palette-section">
          <h2>Palette</h2>
          <div className="palette-colors">
            {[{ color: '#00B3D4', label: '#00B3D4' },
              { color: '#00022A', label: '#00022A' },
              { color: '#0066FF', label: '#0066FF' },
              { color: '#F8F8F8', label: '#F8F8F8' }].map(c => (
              <div className="color-container" key={c.label}>
                <div
                  className="color"
                  style={{
                    backgroundColor: c.color,
                    border: c.color === '#FFFFFF' ? '1px solid black' : 'none',
                  }}
                ></div>
                <p>{c.label}</p>
              </div>
            ))}
          </div>
        </div>

        <div className="logomarks-section">
          <h2>Logomarks</h2>
          <div className="logomarks">
          
            <div className="logomark-item stacked">
              <div className="logomark-subitem">
                <img src={nextWavePrimary} alt="NextWave Primary" />
                <span>Primary Logomark</span>
              </div>
              <div className="logomark-subitem">
                <img src={nextWaveExtended} alt="NextWave Extended" />
                <span>Extended Logomark</span>
              </div>
            </div>
        
            <div className="logomark-item condensed">
              <img src={nextWave} alt="NextWave Condensed" />
              <span>Condensed Logomark</span>
            </div>
          </div>
        </div>

        {/* Mockups */}
        <div className="mockups-section">
          <h2>Mockups</h2>
          <div className="mockups">
            <div>
              <img src={nextWaveBusinessCard} alt="NextWave Gameday" />
             
            </div>
            <div>
              <img src={nextWaveBG} alt="NextWave Postgame" />
              
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Study1;
