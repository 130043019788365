import React from 'react';
import './study.css'; // Updated CSS
import hawksTalk from '../../../assets/designer-pages/case-studies/study1/hawkstalk.png';
import hawksTalkPrimary from '../../../assets/designer-pages/case-studies/study1/hawkstalkprimary.png';
import hawksTalkExtended from '../../../assets/designer-pages/case-studies/study1/hawkstalkextended.png';
import hawksTalkCondensed from '../../../assets/designer-pages/case-studies/study1/hawkstalkcondensed.png';
import hawksTalkGameday from '../../../assets/designer-pages/case-studies/study1/hawkstalkmockup1.png';
import hawksTalkPostgame from '../../../assets/designer-pages/case-studies/study1/hawkstalkmockup2.png';

const Study1 = () => {
  return (
    <div className="study-page">
      <h1 className="title">Hawks Talk</h1>
      <section className="content-section">
        
        {/* The Icon */}
    <div class="icon-section">
      <div class="icon-container">
        <h2>The Icon</h2> 
        <p>
          This logo takes a spin on the name of the brand: Hawks Talk. With
          the use of a wing to capture the hawk motif and a microphone to
          capture the “talk”, and using the Atlanta Hawks color scheme, this
          icon cleverly encapsulates the essence of the brand.
        </p>
      </div>
      <img src={hawksTalk} alt="Hawks Talk Icon" />
    </div>


        {/* The Type */}
        <div className="type-section">
        <div className="font-square-1">Aa</div>
        <div className="text-content">
          <h2>The Type</h2>
          <p>
            Rockwell’s bold, geometric style reflects the strength and energy of the
            Atlanta Hawks, making it perfect for Hawks Talk. Its clean, readable
            design ensures content is accessible across all platforms, supporting the
            brand’s mission to engage and inform fans.
          </p>
        </div>
      </div>

        {/* Palette */}
        <div className="palette-section">
          <h2>Palette</h2>
          <div className="palette-colors">
            {[{ color: '#C7202F', label: '#C7202F' },
              { color: '#FDB924', label: '#FDB924' },
              { color: '#FFFFFF', label: '#FFFFFF' },
              { color: '#000000', label: '#000000' }].map(c => (
              <div className="color-container" key={c.label}>
                <div
                  className="color"
                  style={{
                    backgroundColor: c.color,
                    border: c.color === '#FFFFFF' ? '1px solid black' : 'none',
                  }}
                ></div>
                <p>{c.label}</p>
              </div>
            ))}
          </div>
        </div>
       
        <div className="logomarks-section">
          <h2>Logomarks</h2>
          <div className="logomarks">
          
            <div className="logomark-item stacked">
              <div className="logomark-subitem">
                <img src={hawksTalkPrimary} alt="Hawks Talk Primary" />
                <span>Primary Logomark</span>
              </div>
              <div className="logomark-subitem">
                <img src={hawksTalkExtended} alt="Hawks Talk Extended" />
                <span>Extended Logomark</span>
              </div>
            </div>
        
            <div className="logomark-item condensed">
              <img src={hawksTalkCondensed} alt="Hawks Talk Condensed" />
              <span>Condensed Logomark</span>
            </div>
          </div>
        </div>








        {/* Mockups */}
        <div className="mockups-section">
          <h2>Mockups</h2>
          <div className="mockups">
            <div>
              <img src={hawksTalkGameday} alt="Hawks Talk Gameday" />
             
            </div>
            <div>
              <img src={hawksTalkPostgame} alt="Hawks Talk Postgame" />
              
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Study1;
