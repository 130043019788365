


import React, { useState } from 'react';
import './resume.css';
import softwareResume from '../../../assets/developer-pages/resume/Software_Resume.pdf';
import hardwareResume from '../../../assets/developer-pages/resume/Hardware_Resume.pdf';
import aiResume from '../../../assets/developer-pages/resume/AI_Resume.pdf';

const Resume = () => {
  const [selected, setSelected] = useState('software');

  return (
    <div className="resume-page">
      <h1 className="resume-title">Resume</h1>
      <div className="resume-tabs">
        <button
          className={`resume-tab ${selected === 'software' ? 'active' : ''}`}
          onClick={() => setSelected('software')}
        >
          Software
        </button>
        <button
          className={`resume-tab ${selected === 'hardware' ? 'active' : ''}`}
          onClick={() => setSelected('hardware')}
        >
          Hardware
        </button>
        <button
          className={`resume-tab ${selected === 'ai' ? 'active' : ''}`}
          onClick={() => setSelected('ai')}
        >
          AI
        </button>
      </div>
      <div className="resume-display">
        {selected === 'software' && (
          <iframe
            src={softwareResume}
            title="Software Resume"
            className="resume-pdf"
          ></iframe>
        )}
        {selected === 'hardware' && (
          <iframe
            src={hardwareResume}
            title="Hardware Resume"
            className="resume-pdf"
          ></iframe>
        )}
        {selected === 'ai' && (
          <iframe
            src={aiResume}
            title="AI Resume"
            className="resume-pdf"
          ></iframe>
        )}
      </div>
    </div>
  );
};

export default Resume;